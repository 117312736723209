<template>
  <div class="wrapper">
    <div class="button" @click="click">开始</div>
  </div>
</template>

<script>




export default {
  components: { 
   },
  props: {},
  data() {
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {
    click(){
      this.$router.push({
        path:'/chat'
      })
    }

  },
  created() { 
    this.$nextTick(()=>{
      // 鼠标滑动期间，会不停地多次触发鼠滑动事件，直到不再滑动
    document.addEventListener('mousemove', (event) => {
        console.log('触发1次');
    });
    document.addEventListener('mousemove', (event) => {
    console.log('鼠标每次x方向移动距离', event.movementX);
});
    })
  
   },
  mounted() { }
}
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  
}
.customBtn{
  width: 100vw;
  height: 100vh;
}
.button{
  width: 1.5rem;
  height: .8rem;
  font-size: .35rem;
  border-radius: .35rem;
  background-color: #0052D9;
  color: #fff;
  text-align: center;
  line-height: .8rem;
}
</style>